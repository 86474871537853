/* eslint-disable camelcase */
import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Package from 'components/elements/Package'

interface PackagesStandardProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Packages
}

const PackagesStandard: React.FC<PackagesStandardProps> = ({ fields }) => (
  <section className="bg-background theme-transition">
    <div className="container py-5">
      <ParseContent
        className="text-text pt-lg-5 pb-5"
        content={fields.description || ''}
      />
      <div className="row">
        {fields?.packages?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="col-md-4 pb-5">
            <Package
              fields={
                item as GatsbyTypes.WpPage_Flexcontent_Flex_Packages_packages
              }
            />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default PackagesStandard
