import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  color: ${({ theme }) => theme.color.text};
  border-radius: 25px;
`

const AfterOrderReservation = React.lazy(() =>
  import('@ubo/studio').then((module) => ({ default: module.AfterOrder }))
)

interface AfterOrderProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_AfterOrder
}

const AfterOrder: React.FC<AfterOrderProps> = () => {
  const isSSR = typeof window === 'undefined'
  return (
    <section className="bg-background theme-transition">
      <div className="container py-5 my-5">
        <Box className="p-5">
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <AfterOrderReservation
                reservationPath="/reserveren/"
                language="nl"
              />
            </React.Suspense>
          )}
        </Box>
      </div>
    </section>
  )
}

export default AfterOrder
