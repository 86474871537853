import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

const AlternativeCSS = css`
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: ${({ theme }) => theme.color.allPrimaryLight};
`

const StyledTextCta = styled.section`
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.allLight};

  @media (min-width: 768px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      text-align: right;
    }
  }
`

const Container = styled.div<{ alt: boolean }>`
  ${(props) =>
    props.alt &&
    css`
      ${AlternativeCSS};
    `}
`

interface TextCtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextCta: React.FC<TextCtaProps> = ({ fields }) => (
  <StyledTextCta className="pt-3">
    <Container alt={fields.alternative || false} className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <ParseContent
            className={`pe-md-3 d-flex align-items-center justify-content-md-${
              fields.alternative ? 'center' : 'end'
            } justify-content-center text-center text-md-right`}
            content={fields?.description || ''}
          />
        </div>
        <div
          className={`col-md-6 ps-md-3 d-flex align-items-center justify-content-md-${
            fields.alternative ? 'center' : 'start'
          } justify-content-center mt-4 mt-md-0`}
        >
          <ButtonTertiary to={fields?.link?.url || ''}>
            {fields?.link?.title}
          </ButtonTertiary>
        </div>
      </div>
    </Container>
  </StyledTextCta>
)

export default TextCta
