import React from 'react'

// Libraries
import { InlineWidget } from 'react-calendly'

interface CalendlyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Calendly
}

const Calendly: React.FC<CalendlyProps> = ({ fields }) => (
  <section className="bg-background">
    <div className="container py-5">
      <InlineWidget
        styles={{
          height: 900,
        }}
        url={fields.url || ''}
      />
    </div>
  </section>
)

export default Calendly
