import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.div`
  min-height: 525px;
  position: relative;
`

const WrapperImage = styled.div`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  @media (min-width: 992px) {
    position: absolute;
  }
`

const WrapperWrapperImage = styled.div`
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  display: block;
  position: relative;

  @media (min-width: 1600px) {
    border-radius: 50px;
    overflow: hidden;
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
`

const WrapperContent = styled.div`
  position: relative;
  z-index: 2;
`

const BackgroundImage = styled(Plaatjie)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
`

const Block = styled(ParseContent)<{ alternative: boolean }>`
  border-radius: 25px;
  position: relative;

  ${(props) =>
    props.alternative
      ? css`
          background-color: ${({ theme }) => theme.color.backgroundSecondary};
          color: ${({ theme }) => theme.color.text};

          & h1:first-child,
          & h2:first-child,
          & h3:first-child,
          & h4:first-child,
          & h5:first-child {
            color: ${({ theme }) =>
              theme.darkMode ? theme.color.textAlt : theme.color.allGreyDark};
            text-transform: uppercase;
            font-size: ${({ theme }) => theme.font.size.ultra};
            font-weight: ${({ theme }) => theme.font.weight.light};
            position: absolute;
            z-index: 0;
            top: 0;
            right: 100%;
            white-space: nowrap;
            transform: rotate(270deg);
            transform-origin: right top;
            padding: 1.5rem;
          }
        `
      : css`
          background-color: ${({ theme }) =>
            theme.darkMode ? theme.color.allLight : theme.color.allPrimary};
          color: ${({ theme }) =>
            !theme.darkMode ? theme.color.allLight : theme.color.allPrimary};
          border: 2px solid
            ${({ theme }) =>
              theme.darkMode ? theme.color.contrast : theme.color.allPrimary};
        `};

  & ul,
  p {
    position: relative;
    z-index: 1;
  }

  @media (min-width: 992px) {
    margin-top: -100px;
  }

  @media (max-width: 991px) {
    margin-top: -50px;
  }
`

interface TextImageBackgroundProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextImageBackground: React.FC<TextImageBackgroundProps> = ({
  fields,
}) => (
  <section className="position-relative py-5 my-lg-5">
    <Wrapper className="mt-lg-5">
      <WrapperImage>
        <WrapperWrapperImage>
          <BackgroundImage image={fields?.backgroundimage} alt="" />
          <Overlay />
        </WrapperWrapperImage>
      </WrapperImage>
      <WrapperContent className="container">
        <div
          className={`row${fields?.alternative ? ' justify-content-end' : ''}`}
        >
          <div
            className={`col-12 ${
              fields?.alternative ? 'col-lg-5 col-xl-4' : 'col-lg-6 col-xl-5'
            }`}
          >
            <Block
              alternative={!!fields?.alternative}
              className="p-4 p-lg-5 mb-lg-5"
              content={fields?.description || ''}
            />
          </div>
        </div>
      </WrapperContent>
    </Wrapper>
  </section>
)

export default TextImageBackground
