import React from 'react'
import styled from 'styled-components'

const StyledTitlePrimary = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.light};

  & strong,
  & b {
    font-weight: ${({ theme }) => theme.font.weight.black};
  }

  &:is(h1) {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.giga};
      line-height: ${({ theme }) => theme.font.size.ultra};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.giga};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }

  &:is(h2) {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.giga};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.mega};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  &:is(h3) {
    font-size: ${({ theme }) => theme.font.size.large};
    line-height: ${({ theme }) => theme.font.size.huge};
  }
`

type Types = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface TitlePrimaryProps extends React.HTMLAttributes<{}> {
  as?: Types
}

const TitlePrimary: React.FC<TitlePrimaryProps> = ({
  children,
  as = 'h2',
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTitlePrimary className="title-primary" {...rest} as={as}>
    {children}
  </StyledTitlePrimary>
)

export default TitlePrimary
