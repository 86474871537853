import React from 'react'
import styled, { css } from 'styled-components'

// Components
import Menu from 'components/layout/Header/Menu'
import Brand from 'components/layout/Header/Brand'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import ThemeSwitch from 'components/layout/Header/ThemeSwitch'
import useLayout from 'hooks/useLayout'

const StyledDesktop = styled.div<{ isSticky: boolean }>`
  background-color: ${({ theme }) => theme.color.background};
  transition: background-color 0.3s ease 0s;
  height: 100%;
  position: relative;
  border-bottom-left-radius: 80px;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    `}

  &:after {
    background-color: ${({ theme }) => theme.color.background};
    transition: background-color 0.3s ease 0s;
    content: '';
    position: absolute;
    left: calc(100% - 10px);
    width: 20px;
    top: 0;
    bottom: 0;
  }

  &:before {
    background-color: ${({ theme }) => theme.color.background};
    transition: background-color 0.3s ease 0s;
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 3000px;
    z-index: 0;

    ${(props) =>
      props.isSticky &&
      css`
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      `}
  }

  @media (min-width: 1200px) {
    width: 94%;
  }

  @media (max-width: 1199px) {
    width: 95%;
  }
`

const DesktopMenu = styled.ul`
  z-index: 1;
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
`

const DesktopBrand = styled(Brand)`
  @media (min-width: 1600px) {
    margin-left: 80px;
  }

  @media (max-width: 1599px) {
    margin-left: 50px;
  }
`

const DesktopCta = styled.button`
  ${ButtonPrimaryCSS}
  position: absolute;
  color: ${({ theme }) => theme.color.allLight};
  right: -35px;
  top: calc(100% - 18px);
  z-index: 1;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
`

const Switch = styled(ThemeSwitch)`
  @media (min-width: 1600px) {
    position: absolute;
    left: calc(100% + 100px);
  }

  @media (max-width: 1599px) {
    left: calc(100% + 50px);
  }
`

interface DesktopProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpComponent_Header
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields, isSticky = false }) => {
  const layout = useLayout()
  return (
    <div className="d-none d-lg-flex justify-content-end h-100 w-100">
      <StyledDesktop
        className="d-flex align-items-center justify-content-between"
        isSticky={isSticky}
      >
        <DesktopBrand />
        <DesktopMenu>
          <Menu fields={fields} />
        </DesktopMenu>
        {/* <Switch className="ms-xl-4" /> */}
        <DesktopCta size="s" onClick={() => layout.setHubspotModal(true)}>
          {fields?.cta?.link?.title}
        </DesktopCta>
      </StyledDesktop>
    </div>
  )
}

export default Desktop
