import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Image = styled.div`
  & svg {
    @media (max-width: 575px) {
      max-width: 250px;
      margin: 0 auto;
      display: block;
    }

    & path:not(.prefix__prefix__theme-ignore) {
      fill: ${({ theme }) => theme.color.text};
    }
  }
`

interface TextImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextImage: React.FC<TextImageProps> = ({ fields }) => {
  // @ts-ignore
  const file: any = fields?.svg?.localFile?.childSvgoInline?.inlineSVG

  return (
    <section className="py-lg-5">
      <div className="container py-5">
        <div className="row">
          <Image className="col-sm-4 col-lg-5 pe-sm-5">
            {parse(file || '')}
          </Image>
          <div className="col-sm-8 col-lg-7 ps-sm-5 mt-5 mt-sm-0">
            <ParseContent
              className="text-text"
              content={fields?.description || ''}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextImage
