const theme = {
  transition: 'transition: background-color 0.3s ease 0s;',

  font: {
    family: {
      headings: 'september, sans-serif',
      primary: 'museo-sans, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '20px',
      huge: '30px',
      mega: '40px',
      giga: '50px',
      ultra: '60px',
      insane: '75px',
    },

    weight: {
      extraLight: 200,
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
  },
}

export const lightTheme = {
  ...theme,
  ...{
    darkMode: false,

    color: {
      allPrimary: '#0507EC',
      allGrey: '#F5F5F7',
      allPrimaryLight: '#152848',
      allPrimaryDark: '#152848',
      allGreyDark: '#E5E5E5',
      allLight: '#FFFFFF',
      background: '#FFFFFF',
      backgroundAlt: '#F5F5F7',
      backgroundSecondary: '#335183',
      backgroundDark: '#777777',
      backgroundVoid: '#F5F5F7',
      text: '#1D3660',
      textAlt: '#1D3660',
      textLight: '#FFFFFF',
      textGrey: '#777777',
      contrast: '#0007EC',
      contrastDark: '#070744',
    },
  },
}

export const darkTheme = {
  ...theme,
  ...{
    darkMode: true,

    color: {
      allPrimary: '#1D3660',
      allGrey: '#F5F5F7',
      allPrimaryLight: '#152848',
      allPrimaryDark: '#051a40',
      allGreyDark: '#E5E5E5',
      allLight: '#FFFFFF',
      background: '#0007EC',
      backgroundAlt: '#e',
      backgroundSecondary: '#335183',
      backgroundDark: '#0A162B',
      backgroundVoid: '#000',
      text: '#FFFFFF',
      textAlt: '#1D3660',
      textLight: '#FFFFFF',
      textGrey: '#E5E5E5',
      contrast: '#0007EC',
      contrastDark: '#070744',
    },
  },
}
