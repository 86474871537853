import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const StyledPackage = styled.div`
  ${({ theme }) => theme.transition};
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.color.backgroundSecondary : theme.color.allGrey};
  border-radius: 25px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PackageDescription = styled(ParseContent)`
  position: relative;
  z-index: 1;

  & ul {
    padding-left: 1rem;
  }
`

const PackageTitle = styled.h3`
  color: ${({ theme }) =>
    theme.darkMode ? theme.color.textAlt : theme.color.allGreyDark};
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.font.size.ultra};
    font-weight: ${({ theme }) => theme.font.weight.light};
    position: absolute;
    z-index: 0;
    top: 0;
    right: 100%;
    white-space: nowrap;
    transform: rotate(270deg);
    transform-origin: right top;
  }

  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.font.size.huge};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const PackageRate = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  text-transform: uppercase;

  &:nth-of-type(odd) {
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.color.backgroundAlt : theme.color.allLight};
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`

interface PackageProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Packages_packages
}

const Package: React.FC<PackageProps> = ({ fields }) => (
  <StyledPackage>
    <PackageTitle className="pe-4 ps-md-0 ps-4 pt-md-0 pt-4">
      {fields.title}
    </PackageTitle>
    <PackageDescription
      className="text-text px-4 px-lg-5 pt-md-5 pt-3"
      content={fields.description || ''}
    />
    <div>
      <div className="mt-4 mt-lg-5">
        {fields.rates?.map((rate, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PackageRate key={index} className="ms-lg-4 py-2">
            <div className="row ps-lg-1 mx-0">
              <div className="col-8 ps-lg-4">{rate?.label}</div>
              <div className="col-4">{rate?.price}</div>
            </div>
          </PackageRate>
        ))}
      </div>
      <div className="mt-5 pb-5 px-4 px-lg-5 d-flex justify-content-end">
        <ButtonSecondary to={fields?.link?.url || ''}>
          {fields?.link?.title}
        </ButtonSecondary>
      </div>
    </div>
  </StyledPackage>
)

export default Package
