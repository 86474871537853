/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Chevron from 'img/chevron.inline.svg'
import TitleSecondary from 'components/elements/Titles/TitleSecondary'

interface BannerVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerWrapper = styled.div`
  max-height: 1000px;

  @media (min-width: 1400px) {
    min-height: 600px;
  }
`

const ContentWrapper = styled.div`
  z-index: 3;
`

const ChevronWrapper = styled(motion.div)`
  z-index: 3;
  bottom: 18%;

  & svg {
    width: 35px;
    height: 35px;
  }
`

const Content = styled(ParseContent)`
  & * {
    font-family: ${({ theme }) => theme.font.family.headings};
  }
  & blockquote {
    color: ${({ theme }) => theme.color.allLight};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    font-family: ${({ theme }) => theme.font.family.headings};
    letter-spacing: 0.4px;
    line-height: 0.7;
  }

  @media (min-width: 992px) {
    & blockquote {
      font-size: ${({ theme }) => theme.font.size.giga};
    }
    & p {
      font-size: 128px;
    }
  }

  @media (max-width: 991.98px) {
    & blockquote {
      font-size: ${({ theme }) => theme.font.size.large};
    }
    & p {
      font-size: 60px;
    }
  }

  @media (max-width: 575.98px) {
    & p {
      font-size: 42px;
    }
  }

  @media (max-width: 991.98px) {
    padding-bottom: 4.5rem;
  }
`

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  z-index: 2;
`

const Iframe = styled.iframe`
  top: 0px;
  left: 0px;
  max-height: 1000px;
`

const StyledTitleSecondary = styled(TitleSecondary)`
  @media (max-width: 1399.98px) {
    font-size: ${({ theme }) => theme.font.size.insane} !important;
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.mega} !important;
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.huge} !important;
    line-height: ${({ theme }) => theme.font.size.huge} !important;
  }

  @media (max-width: 374.98px) {
    font-size: ${({ theme }) => theme.font.size.huge} !important;
    line-height: ${({ theme }) => theme.font.size.huge} !important;
  }
`

const BannerVideo: React.FC<BannerVideoProps> = ({ fields }) => {
  const bounceTransition = {
    y: {
      yoyo: Infinity,
    },
  }
  const repeatDelay = (fields.animationtext?.length || 1) - 1

  const handleScrollTo = () => {
    document.getElementById(fields.scrollto || '')?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <section>
      <BannerWrapper className="position-relative">
        <ContentWrapper className="position-absolute w-100 h-100 d-flex flex-column align-items-start justify-content-center">
          <div className="container">
            <Content content={fields.description || ''} />
          </div>
          {/* {fields.animationtext?.map((node, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, visibility: 'hidden' }}
              animate={{
                opacity: [0, 1, 0],
                visibility: 'visible',
              }}
              transition={{
                delay: index + 1,
                duration: 1,
                repeat: Infinity,
                repeatDelay,
              }}
              className="position-absolute"
            >
              <StyledTitleSecondary>{node?.description}</StyledTitleSecondary>
            </motion.div>
          ))} */}
        </ContentWrapper>
        <ChevronWrapper
          className="position-absolute w-100 d-flex justify-content-center"
          animate={{ y: [10, 0] }}
          transition={bounceTransition}
          onClick={() => handleScrollTo()}
          role="button"
        >
          <Chevron />
        </ChevronWrapper>

        <VideoWrapper className="position-relative overflow-hidden">
          <Iframe
            title="UBO Studio headervideo"
            src={`${fields.vimeo}&muted=1&loop=1&autoplay=1&background=1`}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            allow="autoplay"
            className="position-absolute"
          />
        </VideoWrapper>
      </BannerWrapper>
    </section>
  )
}

export default BannerVideo
