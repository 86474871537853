import ParseContent from 'components/shared/ParseContent'
import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  color: ${({ theme }) => theme.color.text};
  border-radius: 25px;
  min-height: 560px;
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.textLight};
`

const Reservation = React.lazy(() =>
  import('@ubo/studio').then((module) => ({ default: module.Reservation }))
)

interface ReserveProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Reserve
}

const Reserve: React.FC<ReserveProps> = ({ fields }) => {
  const isSSR = typeof window === 'undefined'

  return (
    <section className="theme-transition">
      <div className="container py-5">
        <Box className="px-lg-5 py-lg-5 px-sm-4 px-0 py-4">
          {fields.description && (
            <Content className="mb-5" content={fields.description} />
          )}
          <div>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <Reservation language="nl" />
              </React.Suspense>
            )}
          </div>
        </Box>
      </div>
    </section>
  )
}

export default Reserve
