/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
  description: string
}

const ItemImage = styled(Plaatjie)`
  transition: all 0.3s ease-in-out;

  @media (max-width: 991.98px) {
    width: 80px;
  }
`

const Item = styled(motion.div)`
  list-style: none;

  & > a {
    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &:hover {
    & ${ItemImage} {
      transform: scale(1.05);
    }
  }

  @media (min-width: 992px) {
    overflow: hidden;
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    padding-top: 25px;
    min-width: 1100px;
    overflow-x: scroll;
    z-index: -1;
    top: -25px;

    & > div {
      position: relative;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
      padding: 125px 15px 15px 15px;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        overflow: visible;
        height: 550px;
        background: ${({ theme }) => theme.color.background};
      }
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;
    padding-left: 10px;

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const StyledSpan = styled(motion.span)`
  bottom: 30px;
  // background-color: ${({ theme }) => theme.color.contrast};

  line-height: 18px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.allLight};

  @media (min-width: 992px) {
    position: absolute;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 991.98px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
`

const Orange = styled(motion.div)`
  top: 0;
  background-color: ${({ theme }) => theme.color.contrast};
  opacity: 0.9;
`

const MobileInner = styled.div`
  width: 100%;
  bottom: 0;
  z-index: 4;
  & span {
    width: 100%;
    font-size: 12px;
    line-height: 14px;
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields, description }) => {
  const [hover, setHover] = useState(-1)
  return (
    <StyledSubmenu
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
    >
      <div className="d-none d-lg-flex row w-100">
        {fields.map((f, index) => (
          <Item
            className="col-lg-2 mb-4 overflow-hidden h-100"
            onHoverStart={() => setHover(index)}
            onHoverEnd={() => setHover(-1)}
          >
            <div className="position-relative overflow-hidden h-100">
              <NavLink to={f?.link?.url || '/'} key={index} className="h-100">
                <ItemImage
                  image={f?.image}
                  alt=""
                  className="d-lg-block d-none h-100"
                />
                <div
                  className="position-absolute w-full"
                  style={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                  <Orange
                    className="position-absolute w-100"
                    style={{ top: 0, right: 0, left: 0, height: 100 }}
                    initial={{ y: 200 }}
                    animate={hover === index ? { y: 120 } : { y: 220 }}
                    exit={{ y: 200 }}
                  />

                  <StyledSpan className="position-absolute text-lg-center d-flex justify-content-lg-center w-100">
                    {f?.link?.title}
                  </StyledSpan>
                </div>
              </NavLink>
            </div>
          </Item>
        ))}
        <div className="col-3 d-flex align-items-end">
          <Content content={description} />
        </div>
      </div>
      <div
        className="d-flex d-lg-none w-100 mt-3"
        style={{ overflowX: 'scroll' }}
      >
        {fields.map((f, index) => (
          <NavLink
            to={f?.link?.url || '/'}
            key={index}
            className="position-relative me-3 d-flex flex-column"
          >
            <ItemImage image={f?.image} alt="" />
            <MobileInner>
              <span className="d-inline-flex">{f?.link?.title}</span>
            </MobileInner>
          </NavLink>
        ))}
      </div>
    </StyledSubmenu>
  )
}

export default Submenu
