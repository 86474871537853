import React from 'react'
import { Helmet } from 'react-helmet'
import Modal from 'react-modal'
import styled from 'styled-components'
import useMedia from 'use-media'

// Images
import X from 'img/x.inline.svg'

const XWrapper = styled.button`
  top: 0;
  right: 0;
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.75;
  }
`

interface ModalHubspotProps {
  isOpen: boolean
  onRequestClose: any
}

const ModalHubspot: React.FC<ModalHubspotProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const isDesktop = useMedia({ minWidth: 992 })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      width: isDesktop ? '75%' : '100%',
      height: 'fit-content',
      right: 'auto',
      border: 0,
      bottom: 'auto',
      marginRight: '-50%',
      maxWidth: '1000px',
      backgroundColor: 'transparent',
      transform: 'translate(-50%, -41%)',
    },
    overlay: {
      backgroundColor: 'rgb(5, 26, 64, 0.7)',
    },
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
      <div
        className="position-relative d-flex flex-column align-items-end"
        style={{ overflow: 'scroll' }}
      >
        <XWrapper onClick={onRequestClose}>
          <X />
        </XWrapper>
        <div
          className="meetings-iframe-container w-100 h-100"
          data-src="https://meetings-eu1.hubspot.com/jan-jaap-glerum?embed=true"
        />
      </div>
    </Modal>
  )
}

export default ModalHubspot
