import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

// Media
import ChevronRight from 'img/chevron-right.inline.svg'
import ModalVideo from './Modals/ModalVideo'
import PlayBtn from 'img/playbutton.inline.svg'

const ImageWrapper = styled.div`
  width: 100%;
  padding-top: 60%;
  position: relative;
  overflow: hidden;
`

const Image = styled(Plaatjie)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s ease-in-out;
`

const ModalService = styled.div`
  display: block;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    & ${Image} {
      transform: scale(1.05);
    }
  }
`

const StyledService = styled(Link)`
  display: block;
  overflow: hidden;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.black};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.text};

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  &:hover {
    & ${Image} {
      transform: scale(1.05);
    }

    & h2 {
      text-decoration: underline;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
`

const ServiceArrow = styled(ChevronRight)`
  @media (min-width: 992px) {
    height: 34px;
    width: 34px;
  }

  @media (max-width: 991px) {
    height: 24px;
    width: 24px;
  }
`

const PlayButton = styled(PlayBtn)`
  width: 35px;
  height: 35px;
`

const PlayDiv = styled.div`
  z-index: 9;
`

interface ServiceProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Services_services
}

const Service: React.FC<ServiceProps> = ({ fields }) => {

  const [isOpen, setIsOpen] = React.useState(false)

  if (fields.showVideoInPopup) {
    return (
      <>
        <ModalService onClick={() => setIsOpen(!isOpen)}>
          <ImageWrapper>
            <Image alt={fields.link?.title || ''} image={fields.image} />
            <Overlay />
            <PlayDiv className="position-absolute top-50 start-50 translate-middle z-0">
              <PlayButton />
            </PlayDiv>
          </ImageWrapper>
        </ModalService>
        <ModalVideo isOpen={isOpen} onRequestClose={() => setIsOpen(!isOpen)} fields={fields} />
      </>
    )
  }

  return (
    <StyledService to={fields.link?.url || ''}>
      <ImageWrapper>
        <Image alt={fields.link?.title || ''} image={fields.image} />
        <Overlay />
      </ImageWrapper>
      <div className="mt-3 d-flex align-items-center justify-content-between">
        <h2 className="mb-0 pe-2">{fields.link?.title}</h2>
        <ServiceArrow />
      </div>
    </StyledService>
  )
}

export default Service
