/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// CSS
import 'swiper/css'
import 'swiper/css/pagination'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

interface SliderPartnersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
}

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.allPrimaryLight};
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.allLight};
`

const SlideImage = styled(Plaatjie)`
  opacity: 0.6;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

const SliderPartners: React.FC<SliderPartnersProps> = ({ fields }) => (
  <Section className="py-5">
    <div className="container pt-3 pb-5">
      <Content content={fields.description || ''} className="mb-5" />
      <Swiper
        autoplay={{
          delay: 4000,
        }}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 80,
          },
        }}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {fields.slides?.map((slide: any, index: number) => (
          <SwiperSlide key={index} className="h-auto">
            <div className="h-full p-2 sm:p-4">
              <SlideImage image={slide.image} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </Section>
)

export default SliderPartners
