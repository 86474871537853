import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Block = styled.div`
  border-radius: 25px;

  & strong {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }
`

interface TextSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextSmall: React.FC<TextSmallProps> = ({ fields }) => (
  <section className="bg-background">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10 text-text">
          <Block className="bg-backgroundAlt p-lg-5 p-4">
            <ParseContent
              className="text-text"
              content={fields?.description || ''}
            />
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default TextSmall
