import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

interface BannerImageOnlyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const Wrapper = styled.div`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;

  @media (min-width: 1600px) {
    overflow: hidden;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  @media (min-width: 992px) {
    height: 600px;
  }

  @media (max-width: 991px) {
    height: 500px;
  }

  @media (max-width: 767px) {
    height: 400px;
  }

  @media (max-width: 575px) {
    height: 300px;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
`

const BannerImageOnly: React.FC<BannerImageOnlyProps> = ({ fields }) => (
  <section>
    <Wrapper>
      <Image alt="banner" image={fields?.image} />
      <Overlay />
    </Wrapper>
  </section>
)

export default BannerImageOnly
