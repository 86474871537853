import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'
import ModalHubspot from 'components/elements/Modals/ModalHubspot'

export interface LayoutContextProps {
  locale: string
  darkMode: boolean
  pageId: number
  translations: Array<{
    href: string
    locale: string
  }>
  setHubSpotModal: Function
  setIsScrollable: Function
  setDarkMode: Function
}

export const LayoutContext = React.createContext({
  pageId: 0,
  locale: 'nl_NL',
  darkMode: true,
  translations: [],
  setIsScrollable: (bool: any) => bool,
  setHubspotModal: (bool: any) => bool,
  setDarkMode: (bool: any) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  background-color: ${({ theme }) => theme.color.background};
  ${({ theme }) => theme.transition};

  @media (max-width: 991px) {
    padding-top: 70px;
  }
`

interface LayoutProps {
  pageId?: number
  children: any
  locale?: string
  translations?: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'nl_NL',
  translations,
  pageId = 0,
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const [isSticky, setSticky] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean>(true)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const [hubspotModal, setHubspotModal] = useState<boolean>(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  const setDarkModeLocal = (value: boolean) => {
    setDarkMode(value)

    if (typeof window !== 'undefined') {
      localStorage.setItem('darkmode', value.toString())
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localDarkMode = localStorage.getItem('darkmode')
      const isDarkMode =
        !localDarkMode || localStorage.getItem('darkmode') === 'true'

      setDarkMode(isDarkMode)
    }

    setLoaded(true)
  }, [])

  if (!isLoaded) {
    return null
  }

  return (
    <Theme darkMode={darkMode} isScrollable={isScrollable}>
      <LayoutContext.Provider
        value={{
          pageId,
          setDarkMode: setDarkModeLocal,
          setIsScrollable,
          setHubspotModal,
          darkMode,
          locale,
          translations,
        }}
      >
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
        {hubspotModal && (
          <ModalHubspot
            isOpen={hubspotModal}
            onRequestClose={() => setHubspotModal(false)}
          />
        )}
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
