/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

// Images
import ArrowRounded from 'img/arrow-rounded.inline.svg'
import ParseContent from 'components/shared/ParseContent'

const SliderContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.45rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;
`

const SlideEnd = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0.5) 100%
  );
`

const Slider = styled.div<{ extra: string }>`
  overflow-x: scroll;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  ${(props) =>
    props.extra === 'scrollhint' &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.color.contrast};
    `}
`

const SlideImage = styled(Plaatjie)`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 0;
  transition: all 0.3s ease-in-out;
`

const SlideOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.15) 0%,
    rgba(0, 0, 0, 0.5) 90%
  );
`

const Slide = styled(Link)`
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 992px) {
    height: 250px;
    width: 425px;
  }

  @media (max-width: 991px) {
    height: 200px;
    width: 325px;
  }

  @media (max-width: 575px) {
    height: 150px;
    width: 250px;
  }

  &:hover {
    & > ${SlideImage} {
      transform: scale(1.05);
    }

    & h3 {
      text-decoration: underline;
    }
  }
`

const SlideTitle = styled.h3`
  font-weight: ${({ theme }) => theme.font.weight.black};
  color: ${({ theme }) => theme.color.allLight};
  position: relative;
  z-index: 2;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.huge};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const SVGWrapper = styled.div`
  @media (min-width: 992px) {
    margin-top: -1.7rem;
  }

  @media (max-width: 991.98px) {
    margin-top: -1rem;
    margin-left: 10rem;

    & svg {
      width: 30px;
      height: 30px;
    }
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.large};
`

interface SliderOverProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Slider
}

const SliderOver: React.FC<SliderOverProps> = ({ fields }) => {
  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <section className="pb-lg-5 mb-lg-5">
      <div className="container" ref={containerRef} />
      <div className="py-5">
        {fields.extra === 'description' && fields.description && (
          <div className="container">
            <Content content={fields.description} className="mb-4" />
          </div>
        )}
        {marginLeft !== null && (
          <SliderContainer className="container" marginLeft={marginLeft}>
            <SlideEnd />
            <Slider className="pb-5" extra={fields.extra || 'scrollhint'}>
              {fields.slides?.map((slide, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  <Slide to={slide?.link?.url || ''} className="me-5">
                    <SlideImage
                      alt={slide?.link?.title || ''}
                      image={slide?.image}
                    />
                    <SlideOverlay />
                    <SlideTitle>{slide?.link?.title}</SlideTitle>
                  </Slide>
                </div>
              ))}
            </Slider>
          </SliderContainer>
        )}
        {fields.extra === 'scrollhint' && (
          <div className="container">
            <SVGWrapper className="position-relative">
              <ArrowRounded />
            </SVGWrapper>
          </div>
        )}
      </div>
    </section>
  )
}

export default SliderOver
