import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Right = styled.div<{ alternative: boolean }>`
  ${({ theme }) => theme.transition};
  background-color: ${({ theme, alternative }) =>
    alternative ? theme.color.backgroundDark : theme.color.backgroundAlt};
  position: absolute;
  width: 200px;
  top: 0;
  bottom: 0;

  ${(props) =>
    props.alternative
      ? css`
          left: 0;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        `
      : css`
          right: 0;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        `};

  &:after {
    ${({ theme }) => theme.transition};
    background-color: ${({ theme, alternative }) =>
    alternative ? theme.color.backgroundDark : theme.color.backgroundAlt};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2000px;

    ${(props) =>
    props.alternative
      ? css`
            right: 100%;
          `
      : css`
            left: 100%;
          `};
  }
`

const Image = styled(Plaatjie)`
  width: 100%;

  @media (max-width: 576px) {
    height: 150px;
  }
`

interface TextNormalImagesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextNormalImages: React.FC<TextNormalImagesProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row justify-content-end">
        <div
          className={`col-md-6 pe-xl-5 pt-lg-5 pb-5 ${fields?.alternative ? 'order-3' : 'order-1'
            }`}
        >
          <ParseContent
            className={`text-text ${fields?.alternative ? 'ps-lg-5' : 'pe-lg-5'
              }`}
            content={fields?.description || ''}
          />
        </div>
        <div
          className={`col-md-5 ${fields?.alternative ? 'pe-md-5' : 'ps-md-5'
            } py-5 position-relative order-2 d-md-block d-flex`}
        >
          <Right alternative={!!fields?.alternative} />
          <div className="mb-md-4 px-md-0 px-1">
            <Image alt="" image={fields?.normalImage} />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextNormalImages
