import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Media
import ChevronRight from 'img/chevron-right.inline.svg'

export const ButtonSecondaryCSS = css`
  background-color: transparent;
  display: inline-block;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.textLight};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: ${(props) => props.theme.color.textLight};
      text-decoration: underline;
    }

    & svg {
      height: 30px;
      width: 30px;
      margin-left: 0.5rem;
    }

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }
`

const StyledButton = styled.span`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
        <ChevronRight />
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonSecondary
