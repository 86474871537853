/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import TitlePrimary from 'components/elements/Titles/TitlePrimary'
import TitleSecondary from 'components/elements/Titles/TitleSecondary'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  line-height: ${({ theme }) => theme.font.size.huge};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.medium};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.small};
  }

  & p,
  span,
  blockquote {
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    font-family: ${({ theme }) => theme.font.family.headings};
  }

  & h1:last-child,
  & h2:last-child,
  & h3:last-child,
  & h4:last-child,
  & h5:last-child {
    margin-bottom: 0;
  }

  & h1:not(.title-primary),
  & h2:not(.title-primary),
  & h3:not(.title-primary),
  & h4:not(.title-primary),
  & h5:not(.title-primary) {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  & ul {
    & li {
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & table {
    table-layout: fixed;
  }

  & a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  & img {
  }

  & .gatsby-image-wrapper {
    div {
      display: none !important;
    }
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      const attributes = attribs

      if (name === 'a' && attribs.class === 'button-primary') {
        if (attributes && attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        delete attributes.class

        return (
          <ButtonPrimary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'a' && attribs.class === 'button-tertiary') {
        if (attributes && attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        delete attributes.class

        return (
          <ButtonTertiary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonTertiary>
        )
      }

      if (!!name && attribs.class === 'title-primary') {
        if (attributes && attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        delete attributes.class

        return (
          <TitlePrimary as={name} {...attributes}>
            {domToReact(children, {})}
          </TitlePrimary>
        )
      }

      if (!!name && attribs.class === 'title-secondary') {
        if (attributes && attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        delete attributes.class

        return (
          <TitleSecondary as={name} {...attributes}>
            {domToReact(children, {})}
          </TitleSecondary>
        )
      }

      if (name === 'img') {
        return (
          <img
            style={{ position: 'relative' }}
            src={attribs['data-src']}
            alt=""
          />
        )
      }

      if (name === 'a') {
        if (attributes && attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1 ||
          attributes.href.indexOf('tel:') !== -1 ||
          attributes.href.indexOf('mailto:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return (
    <Content className={className}>
      {findAndReplace(content.replace(/#ea6109/g, '#FFFF'))}
    </Content>
  )
}

export default ParseContent
