/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
// import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ParseContent from 'components/shared/ParseContent'
import Submenu from './Submenu'

const Item = styled(motion.li)`
  font-family: ${({ theme }) => theme.font.family.headings} !important;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.textGrey};
    transition: color 0.3s ease 0s;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-transform: uppercase;

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${(props) => props.theme.color.backgroundDark};
    }
  }

  @media (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

const ItemBorder = styled(motion.div)`
  position: relative;
  background-color: ${(props) => props.theme.color.allLight};
  bottom: -5px;
  height: 3px;
  left: 0;
  border-radius: 10px;
`

const SvgWrapper = styled.div<{ open: boolean }>`
  margin-left: 15px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const NavLinkContent = styled(ParseContent)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.headings};
  font-size: 17px;
`

interface MenuProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpComponent_Header
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)
  return (
    <>
      {fields?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
          key={index}
          className={`ms-lg-4 ms-xl-5 ${
            !l?.submenu ? 'position-relative' : ''
          }`}
          initial="rest"
          whileHover="hover"
          animate="rest"
        >
          <div>
            <NavLink to={l?.link?.url || '/'}>
              <NavLinkContent content={l?.link?.title || ''} />
            </NavLink>

            <ItemBorder
              variants={{
                rest: {
                  width: '1%',
                  visibility: 'hidden',
                },
                hover: {
                  width: '100%',
                  visibility: 'visible',
                },
              }}
            />
            {l?.submenu && (
              <SvgWrapper open={open === index} className="d-block d-lg-none">
                <Chevron />
              </SvgWrapper>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && (
                <Submenu fields={l.submenu} description={l.description || ''} />
              )}
            </AnimatePresence>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
