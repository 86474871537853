import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Hooks
import useLayout from 'hooks/useLayout'

// Components
import NavLink from 'components/shared/NavLink'

// Images
import LogoDark from 'img/logo-dark.inline.svg'
import LogoLight from 'img/logo-light.inline.svg'

const StyledBrand = styled(NavLink)`
  z-index: 100;
  overflow: hidden;
  height: 36px;
  width: 69px;
  position: relative;
  font-size: 0;
`

const BrandLogo = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  & > svg {
    height: 36px;
    width: 69px;
  }
`

interface BrandProps {
  className?: string
  type?: 'dark' | 'light'
}

const Brand: React.FC<BrandProps> = ({ className = '', type = '' }) => {
  const layout = useLayout()

  return (
    <StyledBrand
      to={`${{ nl_NL: '/', en_US: '/en/' }[layout.locale]}`}
      className={className}
    >
      <BrandLogo
        animate={
          type === '' && {
            y: layout.darkMode ? 0 : 36,
          }
        }
      >
        {type === 'dark' && <LogoDark />}
        {type === 'light' && <LogoLight />}
        {type === '' && <LogoDark />}
      </BrandLogo>
      {type === '' && (
        <BrandLogo
          animate={{
            y: !layout.darkMode ? 0 : 36,
          }}
        >
          {type === '' && <LogoLight />}
        </BrandLogo>
      )}
      Home
    </StyledBrand>
  )
}

export default Brand
