import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Height = css`
  @media (min-width: 992px) {
    height: 750px;
  }

  @media (max-width: 991px) {
    height: 550px;
  }

  @media (max-width: 575px) {
    height: 400px;
  }
`

const BoxLeft = styled.div<{ alternative: boolean }>`
  background-color: ${({ theme }) => theme.color.text};
  transition: background-color 0.3s ease 0s;
  position: relative;

  ${Height};

  &:after {
    background-color: ${({ theme }) => theme.color.text};
    ${({ theme }) => theme.transition};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2000px;

    ${(props) =>
      props.alternative
        ? css`
            left: 100%;
          `
        : css`
            right: 100%;
          `};
  }
`

const BoxMiddle = styled.div<{ alternative: boolean }>`
  transition: background-color 0.3s ease 0s;
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.color.allGreyDark : theme.color.allPrimaryLight};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${Height};

  &:before {
    content: '';
    ${({ theme, alternative }) => css`
      background-image: linear-gradient(
        to ${alternative ? 'left' : 'right'} top,
        transparent 0%,
        transparent 50%,
        ${theme.color.background} 50%
      );
    `};
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    @media (min-width: 992px) {
      height: 170px;
    }

    @media (max-width: 991px) {
      height: 100px;
    }

    @media (max-width: 575px) {
      height: 50px;
    }
  }

  &:after {
    content: '';
    ${({ theme, alternative }) => css`
      background-image: linear-gradient(
        to ${alternative ? 'left' : 'right'} bottom,
        transparent 0,
        transparent 50%,
        ${theme.color.background} 50%
      );
    `};
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;

    @media (min-width: 992px) {
      height: 220px;
    }

    @media (max-width: 991px) {
      height: 180px;
    }

    @media (max-width: 575px) {
      height: 100px;
    }
  }
`

const BoxMiddleContent = styled(ParseContent)`
  color: ${({ theme }) =>
    theme.darkMode ? theme.color.allPrimaryDark : theme.color.allPrimaryDark};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    @media (min-width: 1200px) {
      font-size: ${({ theme }) => theme.font.size.insane} !important;
      line-height: ${({ theme }) => theme.font.size.insane} !important;
    }

    @media (max-width: 1199px) {
      font-size: ${({ theme }) => theme.font.size.giga} !important;
      line-height: ${({ theme }) => theme.font.size.giga} !important;
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.mega} !important;
      line-height: ${({ theme }) => theme.font.size.mega} !important;
    }

    /* @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    } */
  }
`

const BoxSizeCSS = css`
  @media (min-width: 992px) {
    margin-top: 170px;
    height: 360px;
  }

  @media (max-width: 991px) {
    margin-top: 100px;
    height: 270px;
  }

  @media (max-width: 575px) {
    margin-top: 50px;
    height: 250px;
  }
`

const BoxImage = styled(Plaatjie)`
  ${BoxSizeCSS};
`

const BoxDescription = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.text};
  position: relative;
  color: ${({ theme }) =>
    theme.darkMode ? theme.color.allPrimary : theme.color.allLight};

  ${BoxSizeCSS};

  &:after {
    content: '';
    background-color: ${({ theme }) => theme.color.text};
    position: absolute;
    width: 2000px;
    bottom: 0;
    top: 0;
    right: 100%;
    margin-top: 0 !important;
    ${BoxSizeCSS};
  }
`

interface TextBoxProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextBox: React.FC<TextBoxProps> = ({ fields }) => (
  <section className="bg-background">
    <div className="py-5">
      <div className="container">
        <div className="d-flex d-md-none justify-content-center">
          <BoxMiddleContent
            className="text-text"
            content={fields?.descriptionbox || ''}
          />
        </div>
        <div className="row align-items-start">
          <div
            className={`col-1 p-0 ${
              fields?.alternative ? 'order-3' : 'order-1'
            }`}
          >
            <BoxLeft alternative={!!fields?.alternative} />
          </div>
          <div className="col-2 col-sm-3 col-md-4 p-0 order-2">
            <BoxMiddle alternative={!!fields?.alternative}>
              <BoxMiddleContent
                className="mb-5 d-md-block d-none"
                content={fields?.descriptionbox || ''}
              />
            </BoxMiddle>
          </div>
          <div
            className={`col-9 col-sm-8 col-md-7 p-md-0 ${
              fields?.alternative ? 'pe-0' : 'ps-0'
            } ${fields?.alternative ? 'order-1' : 'order-3'}`}
          >
            {fields?.styletype === 'boxtext' && (
              <BoxDescription
                className="d-flex align-items-center justify-content-center"
                content={fields?.description || ''}
              />
            )}
            {fields?.styletype !== 'boxtext' && (
              <>
                <BoxImage alt="Box" image={fields?.image} />
                <ParseContent
                  className="text-text mt-lg-5 px-lg-5 mt-md-4 px-md-4 mt-3 px-sm-3 px-2"
                  content={fields?.description || ''}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextBox
