import React from 'react'
import styled, { css } from 'styled-components'

const MediaSizes = css`
  @media (min-width: 992px) {
    font-size: 100px;
    line-height: 120px;
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.insane};
    line-height: ${({ theme }) => theme.font.size.insane};
  }

  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.ultra};
    line-height: ${({ theme }) => theme.font.size.insane};
  }
`

const StyledTitleSecondary = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.black};

  color: rgba(255, 255, 255, 0.3);
  text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.85);
  -webkit-text-stroke: 2px #ffffff;

  &:is(h1) {
    ${MediaSizes};
  }

  &:is(h2) {
    ${MediaSizes};
  }
`

type Types = 'h1' | 'h2' | 'h2' | 'h4' | 'h5'

interface TitleSecondaryProps extends React.HTMLAttributes<{}> {
  as?: Types
}

const TitleSecondary: React.FC<TitleSecondaryProps> = ({
  children,
  as = 'h2',
  ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTitleSecondary className="title-primary" {...rest} as={as}>
    {children}
  </StyledTitleSecondary>
)

export default TitleSecondary
