import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  // BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="py-5">
          {/* <BlogFilters /> */}
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

// const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
//   font-weight: ${(props) =>
//     props.selected
//       ? props.theme.font.weight.bold
//       : props.theme.font.weight.light};
// `

// interface BlogFiltersProps {}

// interface BlogFilterProps {
//   node: {
//     id: string
//     name: string
//   }
// }

// const BlogFilters: React.FC<BlogFiltersProps> = () => {
//   const blogBink = useBlogBink()

//   return (
//     <div className="container d-flex">
//       {blogBink.categories.map((category: BlogFilterProps) => (
//         <StyledBlogFilter
//           key={category.node.id}
//           id={category.node.id}
//           selected={blogBink.isSelected(category.node.id)}
//           className="mr-3"
//         >
//           {category.node.name}
//         </StyledBlogFilter>
//       ))}
//     </div>
//   )
// }

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <Content content={fields.description || ''} className="mb-5" />
      <div className="row">
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-lg-4 mb-5">
              <BlogGridPost node={node} blogFields={fields} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: {
    id: string
    title: string
    uri: string
    recap: {
      excerpt: string
      thumbnail: any
    }
  }
}

const Thumbnail = styled(Plaatjie)`
  transition: all 0.3s ease-in-out;
`

const PostWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.color.text};
  max-height: 220px;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.black};
    color: ${({ theme }) => theme.color.allLight};
    z-index: 2;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  &:hover {
    & h2 {
      text-decoration: underline !important;
    }

    & ${Thumbnail} {
      transform: scale(1.05);
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <PostWrapper className="position-relative">
    <NavLink to={node.uri || '#'}>
      <Thumbnail image={node.recap.thumbnail} alt="" />
      <div
        className="position-absolute d-flex flex-column justify-content-center w-100 h-100"
        style={{ top: 0 }}
      >
        <h2 className="text-center">{node.title}</h2>
      </div>
    </NavLink>
  </PostWrapper>
)

export default Blog
