import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const StyledFormContact = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  ${({ theme }) => theme.transition};
  border-top-right-radius: 50px;
  position: relative;
  z-index: 3;

  &:after {
    background-color: ${({ theme }) => theme.color.background};
    transition: inherit;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2000px;
    right: 100%;
  }

  @media (min-width: 1200px) {
    margin-top: -150px;
  }

  @media (max-width: 1199px) {
    margin-top: -100px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  & .form-duuf-group {
    @media (min-width: 992px) {
      margin-bottom: 2rem;
    }

    @media (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    font-family: ${({ theme }) => theme.font.family.primary};
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.color.allLight : theme.color.allGreyDark};
    color: ${({ theme }) => theme.color.allPrimary};
    width: 100%;
    border-color: none;
    border-radius: 25px;
    border-style: solid;
    border-width: 0;
    min-height: 38px;
    padding: 10px 25px;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }

    &::placeholder {
      color: ${({ theme }) => theme.color.allPrimary};
      opacity: 0.7;
    }
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label {
    font-weight: ${({ theme }) => theme.font.weight.light};
    margin-left: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .form-duuf-group-consent {
    padding-left: 1.5rem;
    color: ${({ theme }) => theme.color.textLight};

    & span {
      margin-left: 0.5rem;

      & a {
        color: ${({ theme }) => theme.color.textLight};
        text-decoration: underline;
      }
    }
  }

  .form-duuf-error-consent {
    margin-left: 0 !important;
  }

  & .form-duuf-label,
  & .form-duuf-checkbox-label {
    color: ${({ theme }) => theme.color.text};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.contrast};
    margin-left: 0.25rem;
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  & .form-duuf-error {
    font-size: ${({ theme }) => theme.font.size.small};
    color: tomato;
    margin-top: -0.5rem;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-group-submit {
    margin-bottom: 0;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .form-duuf-confirm {
    background-color: ${({ theme }) => theme.color.backgroundAlt};
    color: ${({ theme }) => theme.color.text};
    border-radius: 25px;

    @media (min-width: 992px) {
      padding: 2rem;
    }

    @media (max-width: 991px) {
      padding: 1.5rem;
    }
  }
`

const DescriptionRight = styled(ParseContent)`
  & h2:not(:first-child) {
    margin-top: 1.5rem;
  }

  & img {
    object-fit: contain !important;
    filter: invert(18%) sepia(62%) saturate(637%) hue-rotate(178deg)
      brightness(92%) contrast(95%);
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container">
      <StyledFormContact className="py-5">
        <div className="row">
          <div className="col-xl-1" />
          <div className="col-xl-11">
            <ParseContent
              className="text-text"
              content={fields.description || ''}
            />
          </div>
        </div>

        <div className="row justify-content-center my-5">
          <div className="col-xl-1" />
          <div className="col-sm-6 col-xl-5 pe-lg-5 position-relative">
            {/* @ts-ignore */}
            <StyledFormDuuf
              size="m"
              id={1}
              generate={false}
              privacyUrl="/privacyverklaring"
              privacyUrlPlacement="privacyverklaring"
            >
              <FormDuufGroup />
              <div className="d-flex justify-content-end">
                <FormDuufSubmit />
              </div>
            </StyledFormDuuf>
          </div>
          <div className="col-sm-6 ps-lg-5 mt-sm-0 mt-5">
            <DescriptionRight
              className="text-text ps-lg-5"
              content={fields?.descriptionright || ''}
            />
          </div>
        </div>
      </StyledFormContact>
    </div>
  </section>
)

export default FormContact
