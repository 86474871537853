import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const MemberImage = styled(Plaatjie)`
  border-radius: 15px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.color.contrast};
  margin: 0 auto;
  display: block;
  transform: translate3d(0, 0, 0);

  /* @media (min-width: 992px) {
    width: 150px;
    height: 150px;
  } */

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 25%;
  }
`

const MemberDescription = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text};
  line-height: ${({ theme }) => theme.font.size.large};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <section className="bg-background py-5">
    <div className="container py-lg-5">
      <ParseContent
        className="text-text"
        content={fields.descriptiontop || ''}
      />
      <div className="row mt-lg-5 mt-4">
        <div className="col-sm-5 col-xl-6">
          <ParseContent
            className="text-text pe-lg-5 pe-sm-4"
            content={fields.description || ''}
          />
        </div>
        <div className="col-sm-7 col-xl-6 mt-5 mt-sm-0">
          <div className="row">
            {fields.team?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-6 col-md-4 mb-4" key={index}>
                <MemberImage image={item?.image} alt="" />
                <MemberDescription
                  className="mt-3"
                  content={item?.description || ''}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Team
