import React from 'react'
import styled from 'styled-components'

interface VideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Video
}

const Wrapper = styled.div`
  @media (min-width: 1400px) {
    min-height: 600px;
  }
`

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  z-index: 2;
`

const Iframe = styled.iframe`
  top: 0px;
  left: 0px;
`

const Video: React.FC<VideoProps> = ({ fields }) => (
  <section>
    <Wrapper className="position-relative">
      <VideoWrapper className="position-relative overflow-hidden">
        <Iframe
          title="UBO Studio video"
          src={`${fields.vimeo}`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allow="autoplay"
          className="position-absolute"
        />
      </VideoWrapper>
    </Wrapper>
  </section>
)

export default Video
