import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Scroll from 'img/scroll.inline.svg'
import Brand from './Header/Brand'

const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.allGrey};
`

const ContentSecondLeft = styled(ParseContent)`
  & p {
    max-height: 180px;
    overflow-y: scroll;
    padding-right: 3rem;
  }
`

const ContentRight = styled(ParseContent)`
  & img {
    object-fit: contain !important;
    filter: invert(18%) sepia(62%) saturate(637%) hue-rotate(178deg)
      brightness(92%) contrast(95%);
  }

  @media (max-width: 575.98px) {
    & td:first-child {
      display: none;
    }
  }
`

const ScrollIndicator = styled.div`
  pointer-events: none;
  right: 17%;
  bottom: 17%;

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: ${({ theme }) => theme.color.allPrimaryDark};
    }
  }

  @media (max-width: 991.98px) {
    bottom: 3%;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.allPrimary};

  @media (min-width: 992px) {
    width: 90%;
  }

  @media (max-width: 991.98px) {
    width: 75%;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          left {
            description
          }
          secondLeft {
            description
          }
          middle {
            description
          }
          right {
            description
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row py-4">
          <div className="col-6 col-sm-5 col-lg-2">
            <ParseContent
              className="text-allPrimary"
              content={fields?.footer?.left?.description || ''}
            />
          </div>
          <div className="position-relative col-6 col-sm-5 col-lg-3">
            <ContentSecondLeft
              className="text-allPrimary"
              content={fields?.footer?.secondLeft?.description || ''}
            />
            <ScrollIndicator className="position-absolute">
              <Scroll />
            </ScrollIndicator>
          </div>
          <div className="col-6 col-sm-12 col-md-5 col-lg-4 px-lg-4 mt-4 mt-lg-0">
            <ParseContent
              className="text-allPrimary ms-lg-4"
              content={fields?.footer?.middle?.description || ''}
            />
          </div>
          <div className="col-6 col-md-3 mt-4 mt-md-0">
            <ContentRight
              className="text-allPrimary"
              content={fields?.footer?.right?.description || ''}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Brand type="light" />
          <Line />
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
