import React from 'react'

// Components
import Text from 'components/flex/Text/Text'
import TextUsps from 'components/flex/Text/TextUsps'
import TextUspsBottom from 'components/flex/Text/TextUspsBottom'
import TextBox from 'components/flex/Text/TextBox'
import TextImage from 'components/flex/Text/TextImage'
import TextImageBackground from 'components/flex/Text/TextImageBackground'
import TextImages from 'components/flex/Text/TextImages'
import TextCta from 'components/flex/Text/TextCta'
import TextTwoBlocks from 'components/flex/Text/TextTwoBlocks'
import TextTitle from 'components/flex/Text/TextTitle'
import TextSmall from 'components/flex/Text/TextSmall'
import TextNormalImages from './TextNormalImage'

interface TextShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    standard: Text,
    usps: TextUsps,
    uspsbottom: TextUspsBottom,
    box: TextBox,
    boxtext: TextBox,
    image: TextImage,
    normalimage: TextNormalImages,
    imagebackground: TextImageBackground,
    images: TextImages,
    cta: TextCta,
    twoblocks: TextTwoBlocks,
    title: TextTitle,
    small: TextSmall,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
