import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Usp from 'components/elements/Usp'

const StyledTextUspsBottom = styled.section`
  z-index: 1;
`

interface TextUspsBottomProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextUspsBottom: React.FC<TextUspsBottomProps> = ({ fields }) => (
  <StyledTextUspsBottom className="position-relative">
    <div className="container py-5">
      <div className="row">
        <div className="col-xl-11">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <ParseContent
                className="text-text pt-lg-4"
                content={fields?.descriptionbox || ''}
              />
            </div>
            <div className="col-md-6 col-lg-7 mt-4 mt-md-1">
              <ParseContent
                className="text-text pt-lg-4 ms-lg-5"
                content={fields?.description || ''}
              />
            </div>
          </div>
          <div className="row my-5 gy-4">
            {fields?.usps?.map((usp, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-4 col-lg-2">
                <Usp icon={usp?.icon} label={usp?.label || ''} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </StyledTextUspsBottom>
)

export default TextUspsBottom
