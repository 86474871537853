/* eslint-disable camelcase */
import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Service from 'components/elements/Service'

import styled from 'styled-components'

interface TextProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Services
}

const Content = styled(ParseContent)`
  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.font.family.headings};
  }
`


const Text: React.FC<TextProps> = ({ fields }) => (
  <section className="bg-background">
    <div className="container pt-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <Content className="text-text" content={fields.description || ''} />

          <div className="row mt-4 mt-lg-1 gx-lg-5 gy-3 gy-lg-5 mb-5 pb-4">
            {fields.services?.map((service, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-md-6">
                <Service
                  fields={
                    service as GatsbyTypes.WpPage_Flexcontent_Flex_Services_services
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Text
