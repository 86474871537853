import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Images
import Playbutton from 'img/playbutton.inline.svg'

// Interface
import { log } from 'console'
import { CasesProps } from './CasesShell'

const Section = styled.section`
  & iframe {
    height: 100% !important;
    width: 80% !important;
  }
  margin-bottom: 3rem;
`

const Overview: React.FC<CasesProps> = ({ fields }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1)

  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.casesQueryQuery>(graphql`
    query casesQuery {
      allWpCase(sort: { order: ASC, fields: menuOrder }) {
        edges {
          node {
            menuOrder
            title
            recap {
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 600)
                  }
                }
              }
              vimeo
            }
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges
  const videos: Array<object> = []

  allWpCase.edges
    // @ts-ignore
    .sort((a, b) => (a.node.menuOrder || 0) - (b.node.menuOrder || 0))
    .forEach((c: any) =>
      videos.push({
        url: c.node.recap?.vimeo,
        type: 'video',
        title: c.node.title,
      })
    )

  console.log('cas', cases)

  return (
    <Section>
      <BlogBink
        posts={cases as BlogBinkPosts}
        id="overview-cases"
        limit={Number(fields.limit)}
      >
        <div>
          <BlogFilters />
          <BlogGrid
            fields={fields}
            setCurrentVideoIndex={setCurrentVideoIndex}
          />
        </div>
      </BlogBink>
      {currentVideoIndex !== -1 && (
        <VideoLightbox
          videos={videos}
          startIndex={currentVideoIndex}
          setCurrentVideoIndex={setCurrentVideoIndex}
        />
      )}
    </Section>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  font-weight: ${(props) =>
    props.selected
      ? props.theme.font.weight.bold
      : props.theme.font.weight.light};
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <div className="container d-flex">
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="mr-3"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cases
  setCurrentVideoIndex: Function
}

const BlogGrid: React.FC<BlogGridProps> = ({
  fields,
  setCurrentVideoIndex,
}) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post, index) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-lg-4 col-6 mb-5">
              <BlogGridPost
                node={node}
                blogFields={fields}
                currentNodeIndex={index}
                setCurrentVideoIndex={setCurrentVideoIndex}
              />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="my-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_Cases
  node: {
    id: string
    title: string
    uri: string
    recap: {
      thumbnail: any
      vimeo: string
    }
  }
  setCurrentVideoIndex: Function
  currentNodeIndex: number
}

const SVGWrapper = styled.div`
  top: 0;
  bottom: 0;
  & svg {
    width: 50px;
    height: 50px;
  }
  transition: all 0.3s ease;
  opacity: 0;
  z-index: 3;
`

const Overlay = styled.div`
  top: 0;
  background: rgba(0, 0, 0, 0.47);
  z-index: 2;
  transition: all 0.3s ease;
  opacity: 0;
`

const PostWrapper = styled(motion.div)`
  cursor: pointer;

  & h3 {
    color: ${({ theme }) => theme.color.text};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  &:hover {
    & ${SVGWrapper} {
      opacity: 1;
    }

    & ${Overlay} {
      opacity: 1;
    }

    & h3 {
      text-decoration: underline;
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({
  node,
  setCurrentVideoIndex,
  currentNodeIndex,
}) => (
  <>
    <PostWrapper
      onClick={() => setCurrentVideoIndex(currentNodeIndex)}
      className="position-relative h-100"
    >
      <div className="position-relative h-75">
        <Overlay className="position-absolute w-100 h-100" />
        <Plaatjie image={node.recap.thumbnail} alt="" className="h-100" />
        <SVGWrapper
          className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ zIndex: 99 }}
        >
          <Playbutton />
        </SVGWrapper>
      </div>

      {/* <VideoWrapper className="position-relative overflow-hidden h-100">
        <Iframe
          title="UBO Studio video"
          src={`${node.recap.vimeo}&controls=1`}
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          className="position-absolute"
        />
      </VideoWrapper> */}
      <h3 className="mt-3">{node.title}</h3>
    </PostWrapper>
  </>
)

interface LightBoxProps {
  videos: Array<object>
  startIndex: number
  setCurrentVideoIndex: Function
}

const VideoLightbox = ({
  videos,
  startIndex,
  setCurrentVideoIndex,
}: LightBoxProps) => (
  <div className="position-relative" style={{ zIndex: 999 }}>
    <ReactImageVideoLightbox
      data={videos}
      startIndex={startIndex}
      showResourceCount
      onCloseCallback={() => setCurrentVideoIndex(-1)}
    />
  </div>
)

export default Overview
