import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const Wrapper = styled.div`
  position: relative;

  @media (min-width: 992px) {
    height: 700px;
  }

  @media (max-width: 991px) {
    height: 600px;
  }

  @media (max-width: 767px) {
    height: 500px;
  }

  @media (max-width: 575px) {
    height: 400px;
  }
`

const WrapperImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`

const WrapperWrapperImage = styled.div`
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  @media (min-width: 1600px) {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
`

const WrapperContent = styled.div`
  position: relative;
  z-index: 1;
`

const Content = styled(ParseContent)`
  @media (max-width: 575.98px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <section>
    <Wrapper>
      <WrapperImage>
        <WrapperWrapperImage>
          <Image alt="banner" objectPosition="top" image={fields?.image} />
          <Overlay />
        </WrapperWrapperImage>
      </WrapperImage>
      <WrapperContent className="container h-100 d-flex align-items-center">
        <Content className="w-100" content={fields.description || ''} />
      </WrapperContent>
    </Wrapper>
  </section>
)

export default BannerDefault
