import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Hooks
import useLayout from 'hooks/useLayout'
import { LayoutContextProps } from 'components/layout/Layout'

// Images
import Sun from 'img/sun-s.inline.svg'
import Moon from 'img/moon-s.inline.svg'

const StyledThemeSwitch = styled.div`
  width: 90px;
`

const SwitchDot = styled(motion.div)`
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.contrast};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: -1px;
  top: -1px;
`

const Switch = styled(motion.div)`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.color.allLight : theme.color.allGreyDark};
  border-radius: 20px;
  width: 30px;
  height: 15px;
  cursor: pointer;
  position: relative;
  font-size: 0;

  &:hover {
    opacity: 0.7;
  }
  z-index: 2;
`

const SwitchIcon = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text};
  margin-bottom: 2px;
  z-index: 2;

  & svg {
    &.moon {
      width: 14px;
      height: 14px;
    }

    &.sun {
      width: 15px;
      height: 15px;
    }

    & path {
      fill: ${({ theme }) => theme.color.text};
    }
  }
`

const ThemeSwitch: React.FC<React.HTMLAttributes<{}>> = ({
  className = '',
}) => {
  const layout: LayoutContextProps = useLayout()

  let currentMode = false

  if (typeof window !== 'undefined') {
    currentMode = localStorage.getItem('darkmode') === 'true'
  }

  return (
    <StyledThemeSwitch
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <SwitchIcon className="me-1">
        <Sun className="sun" />
      </SwitchIcon>
      <Switch
        role="button"
        onClick={() => {
          layout.setDarkMode(!layout.darkMode)
        }}
        animate={layout.darkMode ? 'dark' : 'light'}
        variants={{ light: {}, dark: {} }}
        whileTap={{ scale: 0.85 }}
      >
        <SwitchDot
          initial={currentMode ? { x: 15 } : { x: 0 }}
          variants={{
            light: { x: 0 },
            dark: { x: 15 },
          }}
        />
        Thema wisselen
      </Switch>
      <SwitchIcon className="ms-2">
        <Moon className="moon" />
      </SwitchIcon>
    </StyledThemeSwitch>
  )
}

export default ThemeSwitch
