import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'
import ThemeSwitch from 'components/layout/Header/ThemeSwitch'
import Brand from 'components/layout/Header/Brand'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import useLayout from 'hooks/useLayout'

const MenuWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background};

  /* ${({ theme }) =>
    theme.darkMode &&
    css`
      background: rgb(51, 81, 131);
      background: linear-gradient(
        0deg,
        rgba(51, 81, 131, 1) 0%,
        rgba(29, 54, 96, 1) 100%
      );
    `};

  ${({ theme }) =>
    !theme.darkMode &&
    css`
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(245, 245, 247, 1) 100%
      );
    `}; */

  & ul {
    padding-left: 0;
    margin: 0;
    width: 100%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.text};
  text-transform: uppercase;
`

const MenuList = styled.ul`
  list-style: none;
`

const Height = css`
  height: 600px;
`

const Left = styled.div`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.color.backgroundAlt : theme.color.allPrimaryLight};
  height: 450px;
  margin-top: 50px;
`

const Middle = styled.div`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.color.allGreyDark : theme.color.allPrimaryLight};
  height: 100%;
  position: relative;

  ${Height};

  &:before {
    content: '';
    ${({ theme }) => css`
      background-image: linear-gradient(
        to left top,
        transparent 0%,
        transparent 50%,
        ${theme.color.background} 50%
      );
    `};
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 50px;
  }

  &:after {
    content: '';
    ${({ theme }) => css`
      background-image: linear-gradient(
        to left bottom,
        transparent 0,
        transparent 50%,
        ${theme.color.background} 50%
      );
    `};
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100px;
  }
`

const Right = styled.div`
  background-color: ${({ theme }) => theme.color.text};
  position: relative;

  ${Height};

  &:after {
    background-color: ${({ theme }) => theme.color.text};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 2000px;
  }
`

const MobileCta = styled.button`
  ${ButtonPrimaryCSS}
  color: ${({ theme }) => theme.color.allLight};
  z-index: 1;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  margin-top: 7px;
`

interface MobileProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpComponent_Header
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const layout = useLayout()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-flex justify-content-between align-items-center d-lg-none w-100">
      <Brand />
      <div className="d-flex align-items-center">
        {/* <div className="me-4">
          <ThemeSwitch />
        </div> */}
        <NavButton
          role="button"
          onClick={() => {
            setIsOpen(!isOpen)
            setIsScrollable(false)
          }}
        >
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonTitle>Menu</NavButtonTitle>
        </NavButton>
      </div>
      <AnimatePresence>
        {isOpen && (
          <MenuWrapper
            exit={{
              opacity: 0,
              x: '100%',
            }}
            initial={{
              opacity: 0,
              x: '100%',
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          >
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <div className="container mb-5">
              <div className="row">
                <div className="col-8 p-0">
                  <Left className="ps-4 d-flex align-items-center">
                    <MenuList>
                      <Menu fields={fields} />
                      <li>
                        <MobileCta
                          size="s"
                          onClick={() => layout.setHubspotModal(true)}
                        >
                          {fields?.cta?.link?.title}
                        </MobileCta>
                      </li>
                    </MenuList>
                  </Left>
                </div>
                <div className="col-3 p-0">
                  <Middle />
                </div>
                <div className="col-1 p-0">
                  <Right />
                </div>
              </div>
            </div>
          </MenuWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Mobile
