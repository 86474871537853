import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const StyledTextTitle = styled.section<{ alternative: boolean }>`
  ${(props) =>
    props.alternative &&
    css`
      position: relative;
      z-index: 3;

      @media (min-width: 992px) {
        margin-top: -110px;
      }

      @media (max-width: 991px) {
        margin-top: -90px;
      }

      @media (max-width: 575px) {
        margin-top: -60px;
      }
    `};
`

const Top = styled.div`
  ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.color.background};

  @media (min-width: 992px) {
    height: 110px;
    border-top-left-radius: 75px;
    border-top-right-radius: 75px;
  }

  @media (max-width: 991px) {
    height: 90px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  }

  @media (max-width: 575px) {
    min-height: 60px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
`

const TitleButton = styled(ButtonPrimary)`
  @media (max-width: 992px) {
    min-width: initial;
  }
`

interface TextTitleProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextTitle: React.FC<TextTitleProps> = ({ fields }) => (
  <StyledTextTitle alternative={!!fields?.alternative}>
    <div className="container">
      <Top className="d-flex align-items-center px-3">
        <ParseContent
          className={`text-text${fields?.alternative ? ' w-100' : ''}`}
          content={fields?.description || ''}
        />
        {fields?.link && fields?.link.url !== '#' && (
          <TitleButton className="ms-lg-5 ms-4" to={fields?.link.url || ''}>
            {fields?.link.title}
          </TitleButton>
        )}
      </Top>
    </div>
  </StyledTextTitle>
)

export default TextTitle
