import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Usp from 'components/elements/Usp'

const StyledTextUsps = styled.section`
  z-index: 1;
`

const Left = styled.div`
  ${({ theme }) => theme.transition};
  position: relative;

  &:after {
    ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.color.background};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 2000px;
  }
`

const Right = styled.div`
  ${({ theme }) => theme.transition};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`

interface TextUspsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextUsps: React.FC<TextUspsProps> = ({ fields }) => (
  <StyledTextUsps className="position-relative">
    <div className="container pb-5">
      <div className="row">
        <Left
          className={`col-md-6 ${
            fields.textalign === 'right' ? '' : 'pe-md-5'
          } bg-background pt-5 pb-md-5 pb-4`}
        >
          <ParseContent
            className="text-text pt-lg-4 pe-lg-5"
            content={fields?.description || ''}
          />
        </Left>
        <Right
          className={`position-relative col-md-6 col-xl-5 ps-md-5 bg-background pt-5 pb-0 pe-md-5 ${
            fields.textalign === 'right' ? 'order-first' : ''
          }`}
        >
          <div className="me-lg-5 pt-lg-4">
            <div className="row">
              {fields?.usps?.map((usp, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="col-6 col-sm-4 col-md-6 mb-4">
                  <Usp icon={usp?.icon} label={usp?.label || ''} />
                </div>
              ))}
            </div>
          </div>
        </Right>
      </div>
    </div>
  </StyledTextUsps>
)

export default TextUsps
