import React from 'react'
import styled from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.div`
  margin: 0 auto;
  display: block;
  position: relative;

  @media (min-width: 1625px) {
    max-width: 1400px;
    border-radius: 50px;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    height: 500px;
  }

  @media (max-width: 767px) {
    height: 450px;
  }

  @media (max-width: 575px) {
    height: 350px;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgb(0, 0, 0);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
`

interface ImageStandardProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Image
}

const ImageStandard: React.FC<ImageStandardProps> = ({ fields }) => (
  <section className="bg-background">
    <Wrapper>
      <Image alt="Img" image={fields.image} />
      <Overlay />
    </Wrapper>
  </section>
)

export default ImageStandard
