/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Hooks
import useLayout from 'hooks/useLayout'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;

  @media (min-width: 992px) {
    height: 90px;
  }

  @media (max-width: 991px) {
    background-color: ${({ theme }) => theme.color.background};
    height: 70px;

    ${(props) =>
      props.isSticky &&
      css`
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      `}
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  const layout = useLayout()

  // eslint-disable-next-line
  const query: any = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      nl_NL: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 500
                      placeholder: BLURRED
                    )
                  }
                }
              }
              link {
                title
                url
              }
            }
            description
          }
          cta {
            link {
              title
              url
            }
          }
        }
      }

      en_US: wpComponent(databaseId: { eq: 394 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      width: 500
                      placeholder: BLURRED
                    )
                  }
                }
              }
              link {
                title
                url
              }
            }
            description
          }
          cta {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  const fields = query[layout.locale]

  return (
    <StyledHeader isSticky={isSticky}>
      <Container className="container">
        <Mobile fields={fields.header} setIsScrollable={setIsScrollable} />
        <Desktop isSticky={isSticky} fields={fields.header} />
      </Container>
    </StyledHeader>
  )
}

export default Header
