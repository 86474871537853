import React, { createContext, useRef } from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextTwoBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  z-index: 2;
`

const Iframe = styled.iframe`
  top: 0px;
  left: 0px;
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 23px !important;
      margin-bottom: 15px;
      font-weight: 500 !important;

      line-height: 1.5 !important;
    }
  }
`

export const ScrollContext = createContext({
  element: null,
})

const TextTwoBlocks: React.FC<TextTwoBlocksProps> = ({ fields }) => {
  const scrollRef = useRef<HTMLElement>(null)

  return (
    <section className="bg-background" id={fields.sectionid} ref={scrollRef}>
      <div className="container py-5">
        <div className="row py-lg-5">
          <div className="position-relative col-md-5 col-lg-6">
            <ParseContent
              className="text-text"
              content={fields?.descriptionbox || ''}
            />
            {fields.vimeo && (
              <VideoWrapper className="position-relative overflow-hidden mt-5">
                <Iframe
                  title="UBO Studio video"
                  src={`${fields.vimeo}`}
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  loading="lazy"
                  allow="autoplay"
                  className="position-absolute"
                />
              </VideoWrapper>
            )}
          </div>
          <div className="col-md-7 col-lg-6 mt-4 mt-lg-0">
            <ParseContent
              className="text-text"
              content={fields?.description || ''}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextTwoBlocks
