import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content: string) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

interface SEOProps {
  seo: any
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.socialSharingQueryQuery>(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
      wpComponent(databaseId: { eq: 204 }) {
        socialSharing {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaultImageUrl = wpComponent?.socialSharing?.image
    ? `${site?.siteMetadata?.siteUrl}${wpComponent?.socialSharing?.image?.localFile?.publicURL}`
    : ''

  const image: any = seo.opengraphImage
    ? seo.opengraphImage.sourceUrl
    : defaultImageUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: 'nl',
      }}
      title={removeHTML(seo.title)}
    >
      {/* Default */}
      {seo.metaDesc && (
        <meta name="description" content={removeHTML(seo.metaDesc)} />
      )}
      {seo.canonical && (
        <link
          rel="canonical"
          href={`${site?.siteMetadata?.siteUrl}${seo.canonical}`}
        />
      )}
      <meta name="image" content={image} />
      <link rel="icon" href={favicon} />
      {/* OpenGraph */}
      {seo.opengraphTitle && (
        <meta property="og:type" content={seo.opengraphTitle} />
      )}
      {seo.opengraphUrl && (
        <meta property="og:url" content={seo.opengraphUrl} />
      )}
      {seo.title && (
        <meta property="og:title" content={removeHTML(seo.title)} />
      )}
      {seo.metaDesc && (
        <meta property="og:description" content={removeHTML(seo.metaDesc)} />
      )}
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="nl_NL" />

      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="7511fb31-003a-4e7b-8885-10f9a18b6581"
        type="text/javascript"
        async
      />

      {/* Start of HubSpot Embed Code */}
      {/* <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25411680.js"
      /> */}
      {/*  End of HubSpot Embed Code */}

      {/* <script>
        {`window.Futy = { key: '6181467f267ac' };
        (function (e, t) {
        var n = e.createElement(t);
        n.async = true;
        n.src = 'https://v1.widget.futy.io/js/futy-widget.js';
        var r = e.getElementsByTagName(t)[0];
        r.parentNode.insertBefore(n, r);
        })(document, 'script');`}
      </script> */}
    </Helmet>
  )
}

export default SEO
