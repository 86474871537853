/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Parallax, Background } from 'react-parallax'
import styled from 'styled-components'
import NavLink from 'components/shared/NavLink'
import Plaatjie from '@ubo/plaatjie'

interface HeroProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Hero
}

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.text};

  &:hover {
    color: ${({ theme }) => theme.color.text};
    text-decoration: underline;
  }
`

const HeroWrapper = styled.div`
  @media (min-width: 1400px) {
    height: 550px;
  }

  .react-parallax-background-children {
    width: 100%;
  }
`

const Image = styled(Plaatjie)`
  /* height: 400px; */
`

const Links = styled.div`
  overflow-x: scroll;
`

const Hero: React.FC<HeroProps> = ({ fields }) => {
  const linkAmount = fields.links?.length || 99

  return (
    <section>
      <HeroWrapper className="position-relative">
        <Parallax strength={350} className="w-100 h-100">
          <Background className="custom-bg w-100 h-100">
            <Image image={fields.image} alt="" className="w-100 h-100" />
          </Background>
        </Parallax>
      </HeroWrapper>
      <Links className="bg-backgroundVoid py-4 text-center">
        {fields.links?.map((node, index) => (
          <>
            <StyledNavLink
              to={node?.link?.url || '#'}
              className="text-uppercase"
              key={index}
            >
              <span>{node?.link?.title}</span>
            </StyledNavLink>
            {index !== linkAmount - 1 && <span className="text-text"> - </span>}
          </>
        ))}
      </Links>
    </section>
  )
}

export default Hero
