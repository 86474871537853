import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'

const StyledUsp = styled(motion.div)`
  border: 2px solid
    ${({ theme }) =>
      theme.darkMode ? theme.color.allPrimaryDark : theme.color.allPrimaryDark};
  background-color: ${({ theme }) => theme.color.allPrimaryDark};
  border-radius: 25px;

  & svg {
    margin: 0 auto;
    display: block;

    @media (min-width: 992px) {
      height: 50px;
      width: 75px;
    }

    @media (max-width: 991px) {
      height: 40px;
      width: 60px;
    }

    @media (max-width: 575px) {
      height: 25px;
      width: 30px;
    }

    & path {
      fill: ${({ theme }) =>
        theme.darkMode ? theme.color.text : theme.color.contrast};
    }
  }

  & h2 {
    @media (min-width: 992px) {
      height: 45px;
      font-size: ${({ theme }) => theme.font.size.big};
    }

    @media (max-width: 991px) {
      height: 40px;
      font-size: ${({ theme }) => theme.font.size.medium};
    }

    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
  }
`

interface UspProps {
  icon: any
  label: string
}

const Usp: React.FC<UspProps> = ({ icon, label }) => (
  <StyledUsp className="p-3 p-sm-4" whileHover={{ scale: 1.05 }}>
    {parse(icon?.localFile?.childSvgoInline?.inlineSVG || '')}
    <h2 className="mt-3 mt-sm-4 text-text font-weight-light text-uppercase mb-0 d-flex align-items-center justify-content-center text-center">
      {label}
    </h2>
  </StyledUsp>
)

export default Usp
