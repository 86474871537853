import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import Plaatjie from '@ubo/plaatjie'

// Components
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

// Images
import ArrowRounded from 'img/arrow-rounded.inline.svg'
import Playbutton from 'img/playbutton.inline.svg'

// Interface
import { CasesProps } from 'components/flex/Cases/CasesShell'

const Section = styled.section`
  & iframe {
    height: 100% !important;
    width: 100% !important;
  }
`

const Highlighted: React.FC<CasesProps> = ({ fields }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1)

  const {
    allWpCase,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedCasesQuery>(graphql`
    query highlightedCases {
      allWpCase(sort: { order: ASC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            recap {
              vimeo
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 600)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const cases: unknown = allWpCase.edges
  const postIds = fields.highlighted?.map((c) => c?.databaseId)

  const videos: any = []

  allWpCase.edges.forEach((c) => {
    if (postIds?.includes(c?.node.databaseId)) {
      videos.push({
        url: `${c.node.recap?.vimeo}`,
        type: 'video',
        title: c.node.title,
      })
    }
  })

  return (
    <Section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={cases as BlogBinkPosts}
        id="highlighted-cases"
        limit={Infinity}
      >
        <div className="py-5">
          <BlogGrid
            fields={fields}
            setCurrentVideoIndex={setCurrentVideoIndex}
          />
        </div>
      </BlogBink>
      {currentVideoIndex !== -1 && (
        <VideoLightbox
          videos={videos}
          startIndex={currentVideoIndex}
          setCurrentVideoIndex={setCurrentVideoIndex}
        />
      )}
    </Section>
  )
}

const SliderContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.45rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;
`

const Slider = styled.div<{ extra: string }>`
  overflow-x: scroll;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.contrast};
`

const SlideEnd = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 150px;
  z-index: 1;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0.5) 100%
  );
`

const SVGWrapper = styled.div`
  @media (min-width: 992px) {
    margin-top: -1.7rem;
  }

  @media (max-width: 991.98px) {
    margin-top: -1rem;
    margin-left: 10rem;

    & svg {
      width: 30px;
      height: 30px;
    }
  }
`

interface GridProps extends CasesProps {
  setCurrentVideoIndex: Function
}

const BlogGrid: React.FC<GridProps> = ({ setCurrentVideoIndex }) => {
  const blogBink = useBlogBink()

  // const videos: Array<object> = []

  // blogBink.posts.forEach((c) =>
  //   videos.push({
  //     url: `${c.node.recap?.vimeo}`,
  //     type: 'video',
  //     title: c.node.title,
  //   })
  // )

  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <>
      <div className="container" ref={containerRef} />

      {marginLeft !== null && (
        <SliderContainer className="container" marginLeft={marginLeft}>
          <SlideEnd />
          <Slider className="pb-5" extra="">
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <BlogGridPost
                  node={node}
                  setCurrentVideoIndex={setCurrentVideoIndex}
                  currentNodeIndex={index}
                />
              )
            })}
          </Slider>
        </SliderContainer>
      )}
      <div className="container">
        <SVGWrapper className="position-relative">
          <ArrowRounded />
        </SVGWrapper>
        <div className="d-flex justify-content-center mt-4">
          <ButtonTertiary to="/cases/">
            Bekijk een selectie van ons werk
          </ButtonTertiary>
        </div>
      </div>
    </>
  )
}

const Slide = styled.div`
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 2rem;

  @media (min-width: 992px) {
    height: 250px;
    width: 460px;
  }

  @media (max-width: 991px) {
    height: 200px;
    width: 325px;
  }

  @media (max-width: 575px) {
    height: 150px;
    width: 250px;
  }
`

const SlideTitle = styled.h3`
  font-weight: ${({ theme }) => theme.font.weight.black};
  color: ${({ theme }) => theme.color.text};
  position: relative;
  z-index: 2;

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.huge};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const PostSVGWrapper = styled.div`
  top: 0;
  bottom: 0;
  transition: all 0.3s ease;
  z-index: 3;

  @media (min-width: 1200px) {
    opacity: 0;
  }

  @media (max-width: 1199px) {
    opacity: 1;
  }

  @media (min-width: 576px) {
    & svg {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 575.98px) {
    & svg {
      width: 25px;
      height: 25px;
    }
  }
`

const Overlay = styled.div`
  top: 0;
  background: rgba(0, 0, 0, 0.47);
  z-index: 2;
  transition: all 0.3s ease;
  opacity: 0;
`

const PostWrapper = styled(motion.div)`
  cursor: pointer;

  & h3 {
    color: ${({ theme }) => theme.color.text};
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  &:hover {
    & ${PostSVGWrapper} {
      opacity: 1;
    }

    & ${Overlay} {
      opacity: 1;
    }

    & h3 {
      text-decoration: underline;
    }
  }
`

interface PostProps {
  node: {
    id: string
    title: string
    uri: string
    recap: {
      vimeo: string
      thumbnail: any
    }
  }
  setCurrentVideoIndex: Function
  currentNodeIndex: number
}

const BlogGridPost: React.FC<PostProps> = ({
  node,
  setCurrentVideoIndex,
  currentNodeIndex,
}) => (
  <div className="d-flex flex-column">
    <Slide>
      <PostWrapper
        onClick={() => setCurrentVideoIndex(currentNodeIndex)}
        className="position-relative h-100"
      >
        <div className="position-relative h-100">
          <Overlay
            className="position-absolute w-100 h-100"
            style={{ zIndex: 98 }}
          />
          <Plaatjie image={node.recap.thumbnail} alt="" className="h-100" />
          <PostSVGWrapper
            className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
            style={{ zIndex: 99 }}
          >
            <Playbutton />
          </PostSVGWrapper>
        </div>
      </PostWrapper>
    </Slide>
    <SlideTitle className="mt-3">{node.title}</SlideTitle>
  </div>
)

const LightboxWrap = styled.div`
  & > div {
    & div:nth-child(2) {
      z-index: 9999;
    }
  }
`

interface LightBoxProps {
  videos: Array<object>
  startIndex: number
  setCurrentVideoIndex: Function
}

const VideoLightbox = ({
  videos,
  startIndex,
  setCurrentVideoIndex,
}: LightBoxProps) => (
  <LightboxWrap className="position-relative" style={{ zIndex: 999 }}>
    <ReactImageVideoLightbox
      data={videos}
      startIndex={startIndex}
      showResourceCount
      onCloseCallback={() => setCurrentVideoIndex(-1)}
    />
  </LightboxWrap>
)

export default Highlighted
