import React from 'react'
import styled from 'styled-components'

const Block = styled.div`
  border-radius: 25px;
`

interface PrivacyProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Privacystatement
}

const Privacy: React.FC<PrivacyProps> = () => (
  <section className="bg-background">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10 text-text">
          <Block className="bg-backgroundAlt p-lg-5 p-4">
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/7511fb31-003a-4e7b-8885-10f9a18b6581/cd.js"
              type="text/javascript"
              async
            />
          </Block>
        </div>
      </div>
    </div>
  </section>
)

export default Privacy
