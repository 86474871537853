import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import Plaatjie from '@ubo/plaatjie'

// Libraries
import SplitPane from 'react-split-pane'

// Media
import move from 'img/move.svg'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const containerWidth = css`
  @media (min-width: 1250px) {
    height: 650px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  @media (min-width: 1650px) {
    width: 1600px;
  }

  @media (max-width: 1649px) {
    width: 1400px;
  }

  @media (max-width: 1449px) {
    width: 1200px;
  }

  @media (max-width: 1249px) {
    width: 1000px;
    height: 500px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  @media (max-width: 1049px) {
    width: 800px;
    height: 400px;
  }

  @media (max-width: 849px) {
    width: 600px;
    height: 300px;
  }

  @media (max-width: 649px) {
    width: 400px;
  }
`

const StyledBannerHome = styled.section`
  background-color: ${({ theme }) => theme.color.background};
`

const BannerWrapper = styled.div<{ width?: string }>`
  position: relative;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  z-index: 0;

  @media (min-width: 1050px) {
    margin-bottom: -100px;
  }

  ${containerWidth};

  ${(props) =>
    props.width === 'page' &&
    css`
      border-radius: unset !important;
      margin-top: 6rem;
    `}

  .Pane {
    overflow: hidden;
  }

  .Resizer {
    background: #d62f32;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    position: relative;

    &:after {
      background-color: inherit;
      background-image: url(${move});
      background-size: 60%;
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -20px;
      border-radius: 50%;

      @media (min-width: 992px) {
        height: 40px;
        width: 40px;
        left: calc(-50% + -12px);
      }

      @media (max-width: 991px) {
        height: 30px;
        width: 30px;
        left: calc(-50% + -13px);
      }
    }
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.vertical {
    cursor: col-resize;

    @media (min-width: 992px) {
      width: 18px;
      margin: 0 -9px;
      border-left: 5px solid rgba(255, 255, 255, 0);
      border-right: 5px solid rgba(255, 255, 255, 0);
    }

    @media (max-width: 991px) {
      width: 8px;
      margin: 0 -4px;
      border-left: 3px solid rgba(255, 255, 255, 0);
      border-right: 3px solid rgba(255, 255, 255, 0);
    }
  }

  .Resizer.vertical:hover {
    @media (min-width: 992px) {
      border-left: 5px solid rgba(0, 0, 0, 0.5);
      border-right: 5px solid rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 991px) {
      border-left: 3px solid rgba(0, 0, 0, 0.5);
      border-right: 3px solid rgba(0, 0, 0, 0.5);
    }
  }

  .Resizer.disabled {
    cursor: not-allowed;
  }

  .Resizer.disabled:hover {
    border-color: transparent;
  }
`

const Pane = styled.div<{ right?: boolean }>`
  position: absolute;
  bottom: 0;
  top: 0;

  ${containerWidth};

  ${(props) =>
    props.right &&
    css`
      right: 0;
    `};

  ${(props) =>
    !props.right &&
    css`
      left: 0;
    `};
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

let left = 0

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const handleMousemove = (e: MouseEvent) => {
    const pane1 = document
      .getElementById('split-pane')
      ?.querySelector('.Pane1') as HTMLInputElement

    if (e.offsetX > 20) {
      left = e.offsetX
    }

    pane1.style.width = `${left}px`
  }

  React.useEffect(() => {
    document
      .getElementById('split-pane')
      ?.addEventListener('mousemove', handleMousemove)

    return () => {
      document
        .getElementById('split-pane')
        ?.addEventListener('mousemove', () => handleMousemove)
    }
  }, [])

  return (
    <StyledBannerHome>
      <BannerWrapper id="split-pane" width={fields.width}>
        <SplitPane
          split="vertical"
          defaultSize="51%"
          maxSize="50%"
          minSize="1%"
        >
          <Pane>
            <StyledPlaatjie alt="Links" image={fields?.image} />
          </Pane>
          <Pane right>
            <StyledPlaatjie alt="Rechts" image={fields?.imageright} />
          </Pane>
        </SplitPane>
      </BannerWrapper>
    </StyledBannerHome>
  )
}

export default BannerHome
